<template>
    <ul class="info-list">
        <li v-for="item in infoList" :key="item.informationid" class="info-item">
            <router-link v-if="listType!==2" class="info-link" target="_blank" :to="`/news/detail?id=${item.informationid}`">
                <img class="info-img" :src="item.img" :alt="item.title">
                <span class="info-name text-ellipsis">{{ item.title }}</span>
            </router-link>
            <a v-if="listType===2" href="javascript:void(0)" class="information-item" @click="handlerCommit(item.informationid)">
                <img class="info-img" :src="item.img" :alt="item.title">
                <span class="info-name text-ellipsis">{{ item.title }}</span>
            </a>
            <p class="info-price">
                <span class="info-price-item">
                    出荷时间：
                    <!-- {{ item.publishtype === '1' ? '未定' : (`${item.publishyear}年` + (item.publishtime === 'q0' ? '未定' : item.publishtime)) }} -->
                    {{ $utils.getPublishString(item.publishtype, item.publishyear, item.publishtime) }}
                </span>
                <span class="info-price-item">定价：{{ item.moneytype === '1' ? '未定' : `${item.rmb}元` }}</span>
            </p>
            <p v-if="listType!==2" class="info-series">
                <span class="info-series-name">{{ item.seriestitle }}</span>
                <span class="info-series-date">{{ item.ontime || item.updatetime }} <i class="icon icon-more" /></span>
            </p>
            <span v-if="item.type" class="info-type" :class="[ `info-type-${item.type - 1}` ]">
                {{ [ '可预订', '未出货', '有现货' ][item.type - 1] }}
            </span>
        </li>
    </ul>
</template>
<script>
export default {
    name: 'InfoList',
    props: {
        infoList: {
            type: Array,
            required: true,
            default: null
        },
        listType: {
            type: Number, // 1 infoList列表 2：申请售后产品列表
            default: null
        }
    },
    methods: {
        handlerCommit(informationid) {
            // console.log(informationid)
            this.$emit('handlerCommit', informationid)
        }
    }
}
</script>
<style lang="scss" scoped>
    .info-item {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 285px;
        margin-right: 20px;
        margin-bottom: 20px;
        background-color: $white;
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
    .info-img {
        display: block;
        @include widthHeight(285px, 285px);
        object-fit: cover;
    }
    .info-name {
        padding: 10px;
        display: block;
        height: 35px;
        font-size: 14px;
    }
    .info-price {
        border-bottom: 1px solid $gray;
        padding-bottom: 10px;
    }
    .info-price-item {
        padding: 6px 10px;
        display: block;
        color: $light;
    }
    .info-series {
        height: 47px;
        padding: 16px 10px;
        text-align: justify;
    }
    .info-series-date {
        float: right;
        .icon {
            margin-top: -1px;
        }
    }
    // 类型
    .info-type {
        position: absolute;
        top: 0;
        left: 10px;
        width: 60px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-radius: 0 0 5px 5px;
        color: $white;
        background-color: $red;
    }
    .info-type-1 {
        background-color: $yellow;
    }
    .info-type-2 {
        background-color: $green;
    }
    // 一行3个
    .col-three {
        .info-item {
            &:nth-child(4n) {
                margin-right: 20px;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
</style>
