
// @c an alias to /compontents
import pageHeader from '@c/pageHeader.vue'
import pageFooter from '@c/pageFooter.vue'
import infoList from '@c/infoList.vue'
import listFilter from '@c/listFilter.vue'
import pageAdvert from '@c/pageAdvert.vue'
import { getInformationList } from '@api/news'
import { getSeries } from '@api'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
export default {
    name: 'News',
    mixins: [ mixin, authMixin ],
    components: {
        pageHeader,
        pageFooter,
        infoList,
        pageAdvert,
        listFilter
    },
    watch: {
        '$route'(val) {
            this.title = val.query.title || ''
            this.getData()
        }
    },
    data() {
        return {
            activeName: 'first',
            seriesList: [],
            infoList: [],
            total: 0,
            currentPage: 1,
            seriesid: '',
            sex: 0,
            title: ''
        }
    },
    methods: {
        // 选项卡切换
        handleClick(tab, event) {
            console.log(tab, event)
        },
        // 筛选
        handleFilter(obj) {
            console.log('obj', obj)
            for (const key in obj) {
                console.log(key, obj[key])
                this[key] = obj[key]
            }
            console.log('this.seriesid', this.seriesid)
            console.log('this.sex', this.sex)
            this.getData()
        },
        // 点击页码
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.currentPage = val
            this.getData()
        },
        // 获取筛选下拉
        async getSeries() {
            const res = await getSeries()
            this.seriesList = res.detail
        },
        // 获取资讯列表
        async getData() {
            const res = await getInformationList({
                title: this.title,
                seriesid: this.seriesid,
                sex: this.sex,
                page: this.currentPage
            })
            this.infoList = res.list.list
            this.total = res.list.total
        }
    },
    created() {
        this.getSeries()
        this.getAccount()
    },
    beforeRouteEnter(to, from, next) {
        console.log('to, from', to, from)
        next((vm) => {
            vm.title = to.query && to.query.title ? to.query.title : ''
            vm.getData()
        })
    }
}
